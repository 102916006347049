<ng-container *ngIf="(eventService.showErrorPageEvents$ | async) === 'ERROR_EVENTS'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(eventService.showErrorPageEvents$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(eventService.showErrorPageEvents$ | async) === 'CONTENT_EVENTS'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1">
      {{'EVENTS.HEADER' | translate}}
    </h1>
    <div class="flex justify-center flex-1">
      <app-search [type]="'events'"
                  (onSearchEmitter)="router.navigate(['/events'], {queryParams:  {searchTerm: $event, page: 0}}).then()">
      </app-search>
    </div>
    <div class="flex flex-1 justify-end gap-x-2">
      <form #timeForm="ngForm">
        <div class="grid grid-cols-2 gap-x-2">
          <div class="relative">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 text-highlight absolute inset-y-2 left-1">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
            </svg>
            <input class="input-field w-full !my-0 !pl-7" type="date" id="start" name="start" #start
                   data-date-format="DD.MM.YYYY" min="2018-01-01"
                   [(ngModel)]="fromTime"/>
          </div>
          <div class="relative">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 text-highlight absolute inset-y-2 left-1">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
            </svg>
            <input class="input-field w-full !my-0 !pl-7" type="date" id="end" name="end" #end
                   data-date-format="DD.MM.YYYY" min="2018-01-01"
                   [disabled]="!fromTime" [(ngModel)]="toTime"/>
          </div>
        </div>
      </form>
      <button class="button-outline"
              (click)="clear()"
              type="button">{{'GENERAL.CLEAR' | translate}}</button>
      <button class="button-highlight" (click)="pageInfo.currentPage = 0; eventsByDate(undefined, undefined)"
              type="button">{{'GENERAL.TO_UPDATE' | translate}}</button>
    </div>
  </div>

  <ng-container *ngIf="events && events.length >0">
    <div class="p-5">
      <div class="card-div">
        <div class="table-header-row">
          <div class="w-2/12 table-column">
            <h2>{{'EVENTS.USERNAME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2>{{'EVENTS.CLIENT' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2>{{'EVENTS.TYPE' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2>{{'EVENTS.DESCRIPTION' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2>{{'EVENTS.TIME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column justify-center">
          </div>
          <div class="w-[5px]"></div>
        </div>
        <div class="overflow-y-auto max-h-[calc(100vh-17.5rem)]">
          <div *ngFor="let event of events; let last = last"
               (click)="selectedEvent = event; showInfoModal = true"
               class="table-row cursor-pointer border-b"
               [ngClass]="{'rounded-b-md' : last}">
            <div class="w-2/12 table-column">
              {{(helperService.getObjectByName(event.fields, 'username')).value}}
            </div>
            <div class="w-2/12 table-column">
              {{(helperService.getObjectByName(event.fields, 'clientId')).value}}
            </div>
            <div class="w-2/12 table-column">
              {{(helperService.getObjectByName(event.fields, 'type')).value}}
            </div>
            <div class="w-2/12 table-column">
              {{(helperService.getObjectByName(event.fields, 'description')).value}}
            </div>
            <div class="w-2/12 table-column">
              {{(helperService.getObjectByName(event.fields, 'time')).value | date:'medium'}}
            </div>
            <div class="w-2/12 table-column justify-end">
              <div
                class="hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg (click)="selectedEvent = event; showInfoModal = true"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <app-pagination [pages]="pageInfo"
                        (newPage)="eventsByDate($event, undefined)"></app-pagination>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showInfoModal">
    <app-default-modal
      (toggleModal)="showInfoModal = false"
      [hideButton]="true"
      [jsonObject]="(helperService.getObjectByName(selectedEvent.fields, 'details')).value"
      [textObject]="{header: 'EVENTS.EVENT_DETAILS', text: ''}">
    </app-default-modal>
  </ng-container>

  <ng-container *ngIf="!events || events.length== 0">
    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-4 text-sm" role="alert">
      <p>{{'GENERAL.EMPTY' | translate}}</p>
    </div>
  </ng-container>
</ng-container>
